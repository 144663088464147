import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/detailtravel.scss"
import HeadImg from "../components/headerimg"
import SEO from "../components/seo-rando"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.name}
        description={post.frontmatter.shortdesc}
        keywords={[
          `Trekathlon`,
          `Randonnée`,
          `Rando`,
          `Balade`,
          `Carte`,
          `Travel`,
        ]}
      />
      <section class="voyage">
        <div class="title">
          <HeadImg
            filename={post.frontmatter.picture}
            alt={post.frontmatter.name}
          />
        </div>

        <div class="travelbloc">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        category
        name
        country
        time
        distance
        tag1
        tag2
        tag3
        shortdesc
        picture
        published(formatString: "YYYY/MM/DD")
      }
    }
  }
`
